body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0,0,0, 0.1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #aaa;
}

.main-site > .section {
  margin-top: 50px;
}
@media screen and (max-width: 768px) {
  .main-site > .content {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .main-site > .section {
    margin-top: 0px;
  }
}

.loading-title-holder h1 {
  font-family: 'Kiwi Maru', serif;
}

/* custom css */
.whitebg {
  /* background: #efefef; */
}

.loading-title-holder {
  display: flex;
  padding: 20px;
  background: #f1f1f1;
  box-shadow: 2px 2px 10px rgba(113, 178, 128, 0.5);
  margin-bottom: 20px;
  .spin-cat {
    width: 100px;
    height: 100px;
  }
  .static-cat {
    width: 100px;
    height: 100px;
    opacity: 0;
    position: absolute;
    &.fadein {
      opacity: 1;
    }
  }
  .header-text {
    padding-left: 20px;
    h1 {
      margin-top: 10px;
      font-size: 43px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .loading-title-holder {
    display: block;
    text-align: center;
    z-index: 2;
    margin-bottom: 0px;
    .spin-cat {
      width: 50px;
      height: 50px;
    }
    .static-cat {
      width: 50px;
      height: 50px;
      margin-left:-50px;
    }
    .header-text {
      padding: 0px;
      h1 {
        font-size: 1.5em;
      }
    }
  }
}

.menu-links {
  position: absolute;
  right: 0;
  top: 0;
  text-align: right;
  display: flex;
  > div {
    margin: 10px;
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .mobile-menu {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    font-size: 1.5rem;
  }
  .menu-links {
    height: calc(100vh - 143px);
    // width: 100vw;
    background: #fefefe;
    position: absolute;
    top: 143px;
    width: 100%;
    z-index: 1;
    display: block;
    text-align: center;
    overflow: hidden;
  }
}

.slash-line {
  margin: 0;
  padding: 0;
  margin-left: 530px;
  border-bottom-left-radius: 10px;
  color: #333;
  height: 7px;
  margin-top: -107px;
  background: #134E5E;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #71B280, #134E5E);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #71B280, #134E5E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* cards */
.column {
  &.expanded {
    width: 100%;
    flex: none;
  }
}
.card {
  .tag-refs {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 10px;
    margin-right: 10px;
  }
  .tag-refs.tile-ref {
    margin-bottom: 20px;
    width: 50%;
    bottom: 0;
    top: auto;
  }
  .right-margin {
    margin-right: 10px;
    margin-top: 10px;
  }
  @media screen and (max-width: 768px) {
    .tag-refs.tile-ref .tag {
      font-size: 0.55rem;
    }
  }
}

.list-of-works {
  .card {
    height: 300px;
    max-width: 450px;
    min-width: 450px;
  }
  @media screen and (max-width: 768px) {
    .card {
      min-width: 100%;
      height: auto;
    }
    .title {
      font-size: 1rem;
    }
    .content {
      font-size: 0.7rem;
    }
  }
}

// Single Book
.card {
  display: flex;
  // cursor: pointer;
  .card-content {
    flex: 1;
  }
  .card-image {
    flex: 1;
    max-width: 300px;
  }
  &:hover {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.2), 0 0 0 1px rgba(10, 10, 10, 0.04);
  }
  .tabs ul {
    margin-left: 0;
    // margin-top: 0;
  }
}
@media screen and (max-width: 666px) {
  .card.expanded {
    display : block;
  }
  .card.expanded .tag-refs {
    position: relative;
  }
  .card.expanded .title {
    line-height: normal;
  }
}
@media screen and (min-width: 667px) and (max-width: 769px) {
  .card.expanded {
    display : flex;
  }
  .card.expanded .tag-refs {
    position: relative;
  }
  .card.expanded .title {
    line-height: normal;
  }
}

.chapter {
  font-size: 8px;
  margin-top: -4px;
  vertical-align: middle;
  display: inline-table;
  place-items: center;
  // display:inline-block;
}

.footer {

  border-top-color: #134E5E;  /* fallback for old browsers */
  border-top-color: -webkit-linear-gradient(to right, #71B280, #134E5E);  /* Chrome 10-25, Safari 5.1-6 */
  border-top-color: linear-gradient(to right, #71B280, #134E5E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-top-width: 5px;
  border-top-style: solid;
}

/* custom css animations */
.expanded {
  // transform-origin : left top;
  -webkit-animation: widthUp 0.5s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: widthUp 0.5s; /* Firefox < 16 */
      -ms-animation: widthUp 0.5s; /* Internet Explorer */
       -o-animation: widthUp 0.5s; /* Opera < 12.1 */
          animation: widthUp 0.5s;
}
@keyframes widthUp {
    from { width: 0%; }
    to   { width: 100%; }
}
.removed {
  -webkit-animation: fadeoutColumn 0.5s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadeoutColumn 0.5s; /* Firefox < 16 */
      -ms-animation: fadeoutColumn 0.5s; /* Internet Explorer */
       -o-animation: fadeoutColumn 0.5s; /* Opera < 12.1 */
          animation: fadeoutColumn 0.5s;
}
@keyframes fadeoutColumn {
    from { opacity: 1; }
    to   { opacity: 0; visibility: hidden; }
}
.main-site {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 2s; /* Firefox < 16 */
      -ms-animation: fadein 2s; /* Internet Explorer */
       -o-animation: fadein 2s; /* Opera < 12.1 */
          animation: fadein 2s;
}
@keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1; }
}
.spin-cat.fadeout {
  opacity: 0;
  -webkit-animation: fadeout 2s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadeout 2s; /* Firefox < 16 */
      -ms-animation: fadeout 2s; /* Internet Explorer */
       -o-animation: fadeout 2s; /* Opera < 12.1 */
          animation: fadeout 2s;
}
@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}
